import React, {useState, useEffect} from 'react'
import BorderBtn from './BorderBtn';
import Image from 'next/image';

import {desktopImage} from './DataSet/Base64Images'
export default function HeroBanner({ customClass, customHeight, customPosition, buttonOnBanner, background, mobileBg, buttonTitle, buttonLink, title, description, shortDescription, mainTitle }) {

  const [DataRendered, setDataRendered] = useState(false);
  const [height, setHeight] = useState(0);
  const [device, setDevice] = useState('desktop');
  const [width, setWidth] = useState(0);
  useEffect(()=>{
    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

   
     let device=localStorage.getItem('device');
     setDevice(device)
     let v_height;
     if (device === 'mobile' ) {
      v_height = (windowWidth * 145) / 125;
    } else if (device === 'tablet') {
      v_height = (windowWidth * 80) / 125;
    } else {
      v_height = (windowWidth * 40) / 125;
    }
    setHeight(v_height+"px");
    setWidth(windowWidth+"px");
    if(v_height>0){
      setDataRendered(true)
    }
  },[])
  return (
    <>
      {(DataRendered) &&

        <div className={`mt-65 lg:h-[${height}] w-full mx-auto relative flex items-center`}>
          <div className='w-full h-70' style={{ 
            position:'relative', 
            height:{height} }}>
              {(background) && 
              <>
                <Image priority={true} placeholder='blur'
                blurDataURL={desktopImage}
                src={background} quality={100} width={1500} height={1500}  className={` hidden lg:block w-full`} style={{height: height}} alt="Page Banner"/>
                <Image priority={true}  placeholder='blur' 
                blurDataURL={desktopImage}
                src={(title || description)?desktopImage:background} quality={100}  width={1500} height={500} className={` lg:hidden block  w-full`} 
                style={{ height: ((title || description)?'437px':'210px')}}
                alt="Page Banner for mobile screens"/>
              </>
              }

        {/* <div className={`mt-65 lg:h-[${height}] w-full mx-auto relative flex items-center`}>
            <div 
              // className={`lg:h-${height} h-${customHeight ? customHeight : height}`} 
              style={{
                backgroundImage: `url(${background})`,
                height: `${customHeight ? (device=='mobile')?customHeight:height : height}`,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                backgroundPosition:`${customPosition ? customPosition : 'center'}`,
                backgroundSize: 'cover',
                backgroundRepeat:'no-repeat',
              }}
            > */}
            {/* <Image priority={true} src={background} quality={100} width={1500} height={1500} className={`lg:h-[${height}] w-full`} style={{height: height}} alt="Delveinsight Banner"/> */}

          </div>
          <div className='absolute flex items-center w-full'>
            <div className={`w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col mx-auto lg:justify-between relative`}>
              <h1 className={customClass ? "lg:w-6/12 text-3xl font-semibold  capitalize "+customClass : "lg:w-6/12 text-3xl font-semibold text-white"}>{title}</h1>
              <div className={customClass ? " mt-4 mb-6 "+customClass  : "text-white line-clamp-3 md:line-clamp-6 lg:w-6/12 mt-4 mb-6"  }>{description}</div>
              {/* {buttonTitle ?
                <button className='bg-[#1b5df3] text-white btn-primary lg:px-20 px-10 lg:mt-10 my-5 py-2 w-fit rounded'>{buttonTitle}</button>
                :
                ""} */}
                <div className="text-left">{buttonOnBanner}</div>
                {buttonTitle ?
                  <BorderBtn 
                    customClass="bg-[#1b5df3] text-white btn-primary lg:px-20 px-10 lg:mt-10 my-5 py-2 w-fit rounded border-0"
                    buttonTitle={buttonTitle}
                    buttonLink={buttonLink}
                  />
                  :
                  ""
                }
            </div>
          </div>
        </div>
      }
    </>
  )
}
