const rareDiseasesContactList = {
    title: "To succeed in the rare disease market, research companies must have a distinct business model and a carefully tailored set of capabilities in R&D, market access, and commercial aspects. We provide the best strategy and operating model required to succeed in this winner-take-all market. ",
    description: "Navigating rare diseases can be challenging — but you don’t have to do it alone. Our team of specialists is here to provide you with personalized guidance and the latest treatment insights. Get answers to your questions and explore tailored care options. Take the first step toward clarity and support — schedule your expert consultation today!",
    imageSrc: "/img/industry/oncology/Get-expert-consultation.png",
    altText: "Healthcare Congress Business Strategie",
    points:[
        {
            title: "More than 200 orphan disease reports are in the repository to provide a better understanding of the rare disease market landscape. ",
        },
        {
            title: "Epidemiological assessment of unexplored rare diseases and their forecast patterns.",
        },
        {
            title: "Understand the treatment paradigms and patient journeys across different geographies",
        },
        {
            title: "Stay ahead in competition with the conclusions derived from the perspectives of indication-based KOL panels for different countries",
        },
        {
            title: "Best-in-class strategies for rare disease assets in different clinical trial phases.",
        },
        {
            title: "Strategizing Rare disease development business model",
        },
        {
            title: "Analyze the needs of the patient and caregiver in a disease state to create a patient-centric model.",
        },
        {
            title: "Expert opinions from key industry leaders to validate assumptions and rationale for better market assessment.",
        },
    ]
};
const oncologyContactList = {
    title: "We specialize in oncology market solutions for both large and small companies needing an expert perspective to resolve current challenges and to get estimates to maximize outcomes. Our entire team has extensive oncology experience and knowledge. ",
    imageSrc: "/img/industry/oncology/Get-expert-consultation.png",
    altText: "Healthcare Congress Business Strategie",
    points:[
        {
            title: "We have more than 600 oncology reports derived through patient-based market models in our repository to provide a better understanding of the oncology market landscape.",
        },
        {
            title: "Our syndicated reports and customized consulting solutions assist oncology companies in making informed decisions.",
        },
        {
            title: "Our extensive oncology domain KOL associated with us, bringing different perspectives and providing innovative thinking to our client strategy solutions.",
        },
        {
            title: "We attend key oncology conferences regularly to monitor changing market dynamics, understand emerging therapies, and take note of evolving treatment paradigms.",
        }
    ]
};
const competitiveContactList = {
    title: "We believe that each partner we have on our journey to provide the best competitive intelligence consulting advice and perspectives is special and unique, and so are their business and competitive intelligence consulting goals. This is why our company is dedicated to providing 100% customizable solutions.",
    imageSrc: "img/consulting/jpg/service/contactList.png",

    altText: "Healthcare Congress Business Strategie",
    points:[
        {
            title: "Receiving insights that are relevant to you",
        },
        {
            title: "Achieving fully customized project detailing solutions based on needs",
        },
        {
            title: "Two-way engagement to ensure positive and significant results",
        },
        {
            title: "Benefitting through the achievement of your competitive vision",
        },
        {
            title: "Adding value to you and your organization",
        },
    ]
};
const eularContactList = {
    mainTitle: "Our long standing experience and rich expertise in the rheumatology domain can be a perfect partner for your pressing business problems",
    shortDescription: <>
        <p>Our in-depth knowledge of the rheumatology pipeline, granular understanding of market dynamics and acute perception of marketing nuances can help you create a highly successful plan for future performance.</p>
        <p>Get in touch with us to discuss potential partnership and to know more about what we can offer you.</p>
    </>,
    imageSrc: "/img/industry/oncology/Get-expert-consultation.png",
    altText: "Eular Conference"
};
const atsContactList = {
    mainTitle: "Our long standing experience and rich expertise in the respiratory domain can be a perfect partner for your pressing business problems",
    shortDescription: <>
        <p>Our in-depth knowledge of the respiratory pipeline, granular understanding of market dynamics and acute perception of marketing nuances can help you create a highly successful plan for future performance.</p>
        <p>Get in touch with us to discuss potential partnership and to know more about what we can offer you.</p>
    </>,
    imageSrc: "/img/industry/oncology/Get-expert-consultation.png",
    altText: "ATS Conference 2022"
};
const atsContactList2024 = {
    mainTitle: "Our extensive history and deep knowledge in the field of respiratory health make us an ideal ally for addressing your urgent business challenges. ",
    shortDescription: <>
        <p>Leveraging our comprehensive understanding of the respiratory pipeline, nuanced grasp of market dynamics, and keen insight into marketing intricacies, we can assist you in devising a highly effective strategy for future success.</p>
        <p>Reach out to us to explore potential collaboration opportunities and learn more about the valuable solutions we can provide.</p>
    </>,
    imageSrc: "/img/events/conference/ats-conference/get-expert-consultation-now.png",
    altText: "ATS Conference 2024"
};
const ehaContactList2024 = {
    mainTitle: "Our extensive history and deep knowledge in the field of hematology make us an ideal ally for addressing your urgent business challenges.",
    shortDescription: <>
        <p>Leveraging our comprehensive understanding of the hematology pipeline, nuanced grasp of market dynamics, and keen insight into marketing intricacies, we can assist you in devising a highly effective strategy for future success.</p>
        <p>Reach out to us to explore potential collaboration opportunities and learn more about the valuable solutions we can provide.</p>
    </>,
    imageSrc: "/img/events/conference/eha-conference/get-expert-consultation-now.png",
    altText: "EHA Conference 2024"
};
const eularContactList2024 = {
    mainTitle: "Our extensive history and deep knowledge in the field of rheumatology make us an ideal ally for addressing your urgent business challenges.",
    shortDescription: <>
        <p>Leveraging our comprehensive understanding of the rheumatology pipeline, nuanced grasp of market dynamics, and keen insight into marketing intricacies, we can assist you in devising a highly effective strategy for future success.</p>
        <p>Reach out to us to explore potential collaboration opportunities and learn more about the valuable solutions we can provide.</p>
    </>,
    imageSrc: "/img/events/conference/eular-conference/get-expert-consultation-now.png",
    altText: "Eular Conference 2024"
};
const aacrContactList2025 = {
    mainTitle: "Our extensive history and deep knowledge in the field of oncology make us an ideal ally for addressing your urgent business challenges",
    shortDescription: <>
        <p>Leveraging our comprehensive understanding of the oncology pipeline, nuanced grasp of market dynamics, and keen insight into marketing intricacies, we can assist you in devising a highly effective strategy for future success.</p>
        <p>Reach out to us to explore potential collaboration opportunities and learn more about the valuable solutions we can provide.</p>
    </>,
    imageSrc: "/img/aacr/experts.png",
    altText: "Aacr Annual Meeting Conference 2025",
};
const ersContactList2024 = {
    mainTitle: "Our extensive history and deep knowledge in the field of respiratory health make us an ideal ally for addressing your urgent business challenges.",
    shortDescription: <>
        <p>Leveraging our comprehensive understanding of the respiratory pipeline, nuanced grasp of market dynamics, and keen insight into marketing intricacies, we can assist you in devising a highly effective strategy for future success.</p>
        <p>Reach out to us to explore potential collaboration opportunities and learn more about the valuable solutions we can provide.</p>
    </>,
    imageSrc: "products/report-image/1724224116004-get-expert-consultation-now.png",
    altText: "ERS Conference 2024"
};
    // Add more years as needed
const ersContactList = {
    mainTitle: "Our extensive history and deep knowledge in the field of respiratory health make us an ideal collaborator for addressing your urgent business challenges.",
    shortDescription: <>
        <p>We possess a comprehensive grasp of the respiratory industry&apos;s landscape, a detailed insight into market trends, and a keen awareness of marketing intricacies. These strengths can empower you to develop a remarkably effective strategy for future success. </p>
        <p>Feel free to reach out to us for a conversation about potential collaboration and to explore the extensive value we can bring to your endeavors.</p>
    </>,
    imageSrc: "/img/industry/oncology/Get-expert-consultation.png",
    altText: "ERS Conference"
}

const ascoContactList = {
    mainTitle: "Our extensive history and deep knowledge in the field of oncology make us an ideal ally for addressing your urgent business challenges",
    shortDescription: <>
        <p>Leveraging our comprehensive understanding of the oncology pipeline, nuanced grasp of market dynamics, and keen insight into marketing intricacies, we can assist you in devising a highly effective strategy for future success.</p>
        <p>Reach out to us to explore potential collaboration opportunities and learn more about the valuable solutions we can provide.</p>
    </>,
    imageSrc: "/img/industry/oncology/Get-expert-consultation.png",
    altText: "Healthcare Congress Business Strategie"
}

export {
    rareDiseasesContactList,
    competitiveContactList,
    oncologyContactList,
    ascoContactList,
    eularContactList,
    atsContactList,
    ersContactList,
    atsContactList2024,
    ehaContactList2024,
    eularContactList2024,
    ersContactList2024,
    aacrContactList2025,
}